import * as Yup from "yup";
import { toast } from "react-toastify";
import { Anchor, H1, H3 } from "../../components/Text";
import { useParams } from "react-router-dom";
import { Request } from "../../hooks/Request";
import Circle from "../../components/Animation/Circle";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { headerList } from "../../utils/constant";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import { Button } from "../../components/Buttons";

const CompleteDocuments = () => {
  const { rolId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();
  function handleProcedure(procedure, reload) {
    openModal(<FormFileUpload procedure={procedure} reload={reload} />);
  }
  //   function handleProcedure(procedure) {
  //   openModal(<ListDocument procedure={procedure} />)
  function generateXlsx() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de archivo?</H3>
        }
        onClose={dialogClose}
        method="get"
        url="/adafalabella/detailPicture"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }
  return (
    <CallServiceTable
      urlApi="/procedure/openprocedures"
      // addFilters={headerList}
      addFilters={[
        // {
        //   name: "procedureNumber",
        //   label: "Tramite",
        //   filter: true,
        // },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        // {
        //   name: "clientCode",
        //   label: "Cod. Cliente",
        //   filter: true,
        // },
        // {
        //   name: "dimNumber",
        //   label: "Nro. DIM/DEX",
        //   filter: true,
        // },
        // {
        //   name: "invoice",
        //   label: "Factura",
        //   filter: true,
        // },
        // {
        //   name: "docEmbarque",
        //   label: "Embarque",
        //   filter: true,
        // },
      ]}
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
      ]}
      header={headerList}
      addUrl={`status=pending&rolId=${rolId}`}
      onClick={handleProcedure}
      statusPaination={true}
      component={({ reload }) => {
        return (
          <>
            <Button
              position="left"
              content="Generar reporte de carpetas"
              onClick={() => generateXlsx(reload)}
            >
              <i className="fa-regular fa-file"></i>
            </Button>
          </>
        );
      }}
    />
  );
};

const FormFileUpload = (props) => {
  const { procedure } = props;
  const { data, call, loading } = Request({
    urlApi: `/field/uploadedfileFields/${procedure.id}`,
  });

  async function onSubmit(value) {
    let formData = new FormData();
    formData.append("Name", value.FieldId);
    formData.append("File", value.File);
    formData.append("originalName", value.File.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id);
    const fileContent = await requestAuth("post", "/files", formData)
      .then((res) => {
        return {
          Value: res.data.url,
          ProcedureId: procedure.id,
          FieldId: parseInt(value.FieldId),
          FileId: res.data.id,
        };
      })
      .catch((error) => {
        toast.error(
          error.response.data
            ? error.response.data
            : "Se produjo un error al subir el archivo"
        );
      });
    submitDataset(fileContent);
  }

  async function submitDataset(value) {
    await requestAuth("post", "/dataSet/NewDataSet", [value])
      .then(() => {
        call();
        toast.success("El archivo se subió exitosamente");
      })
      .catch(() => toast.error("No se pudo subir el archivo"));
  }

  return (
    <div>
      <H1>Subir documento</H1>
      <FormDefault
        fields={[
          {
            label: "Adjuntar un archivo",
            name: "File",
            id: procedure.id,
            typeInput: "completeDocuments",
          },
        ]}
        initialValues={{
          FieldId: "",
          File: null,
        }}
        validationSchema={Yup.object().shape({
          FieldId: Yup.string().required("Este campo es obligatorio"),
          File: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={onSubmit}
        buttonName="Guardar"
      />
    </div>
  );
};
export default CompleteDocuments;
